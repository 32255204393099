import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    course:"1"
  },
  getters: {
    getCourse(state) {
      return state.course;
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
