<template>
  <v-container class="fill-h">
    <v-row class="text-center fill-h" align-content="stretch">
      <Logo />
      <v-col cols="12">
        <v-card color="#D62839" class="rounded-xl">
          <v-card-title primary-title class="white--text">
            Merci de saisir le numéro de la course : 
          </v-card-title>
          <v-text-field
            :rules="CourseNumberRules"
            v-model="CourseNumber"
            @keyup.enter="submit()"
            elevation="0"
            class="mx-3 py-6"
            append-icon="mdi-magnify"
            label="N° Course"
            solo
            rounded
            hide-details
            required
          ></v-text-field>
          <v-btn @click="submit()" class="mb-3"> Valider </v-btn>
        </v-card>
      </v-col>
      <Footer />
    </v-row>
  </v-container>
</template>

<script>
import Logo from "../components/Home/Logo.vue";
import Footer from "../components/Home/Footer.vue";

export default {
  name: "login",
  data: () => ({
    CourseNumberRules: [(v) => !!v || "Le code de la course est requis"],
    CourseNumber: "",
  }),
  methods: {
    submit() {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `http://localhost/${this.CourseNumber}/api/IYJW91YIDX2JS2VN0Y3WS3LO453OWW8D`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.error) {
            alert("numero de course invalide")
          } else {
            this.$store.state.course = this.CourseNumber;
            this.$router.push('/home');
          }
        })
        .catch((error) => {alert("numero de course invalide")});
    },
  },
  components: {
    Logo,
    Footer,
  },
};
</script>

<style>
.fill-h {
  height: 100%;
}
</style>
