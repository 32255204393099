<template>
    <v-col cols="12">
        <v-img :src="require('../../assets/logo.png')" class="my-2" contain height="150" />
    </v-col>
</template>

<script>
export default {
    name: 'Logo'
};
</script>